const data = {
  id: 44727473,
  link: 'https://forms.gle/4nA3v4tKXY5Jgns56',
  url: 'https://zastrahovano.com.ua',
  project: ['ЗАСТРАХОВАНО', 'ZASTRAHOVANO'],
  name: ['Zastrahovano', 'Zastrahovano'],
  email: 'admin@zastrahovano.com.ua',
  address: [
    'Україна, 65007, Одеська область, місто Одеса, вулиця Старопортофранківська, 107 квартира 21',
    'Ukraine, 65007, Odessa, Odessa region, Staroportofrankivska Street 107 apartment 21',
  ],
};

export { data };
